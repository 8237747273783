import React from "react";
import Style from "./secondSection.module.css";

const index = () => {
  return (
    <div className={Style.About_Section}>
      <span className={Style.title}>About</span>
      <h2 className={Style.heading}>Hussain Jamil</h2>

      <div className={Style.About_flex}>
        <div className={Style.About_flex_first}>
          <div className={Style.About_Section_line}>
            <span className={Style.line}></span>
          
          </div>
          <div className={Style.About_Section_detail}>
            <h6 className={Style.About_heading}>
              I question the originality of the vision that a mirror offers
            </h6>
            <p className={Style.about_content}>
              I see it from the perspective of “mirror telling a lie since
              ages”. When we see words in mirrors we see them as inverted but
              when we see our self our mind cant judge the trick of the mirror.
            </p>
            <p className={Style.about_content}>
              This very duality and spontaneity of the material boggles the mind
              and makes one ponder upon Plato’s notion of the reality of forms.
              Questioning the originality of the vision, the idea of illusion is
              the sub idea of a mirror in my body of work.
            </p>
            <h6 className={Style.About_heading}>Artist's Statement</h6>
            <p className={Style.about_content}>
              Beauty lies in imperfection. True to our belief that nothing is
              permanent and everyone must follow their life cycle, I believe
              this also applies to my art. My art may seem like its crumpled but
              every bend has a story to tell, a feeling it evokes. I know it'll
              age, rust, and crack over time. Behind every scar there is a
              story, and that makes us different, original. I want to celebrate
              that story and imperfection, because therein lies true beauty. My
              mirrors are dented, but they still sparkle.
            </p>
            <img
              className={Style.signature}
              src="./images/Signaturesmall.png"
              alt=""
            />
          </div>
        </div>

        <div className={Style.About_flex_second}>
          <div className={Style.image_section}>
            <img
              className={Style.side_image}
              src="./images/about.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
