
import { useState } from "react";
import Style from  "./sitespecific.module.css"



function ImageGallery({images}) {
    const [imagesToShow, setImagesToShow] = useState(12);
    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);
    function handleLoadMore() {
        setImagesToShow(imagesToShow + 6);
      }
    //looping through our images array to create img elements
    const imageCards = images.slice(0, imagesToShow).map((image,index) => (

  
        <div className={Style.image_container} key={index} onClick={() => showImage(image)}>
  
  <div className={Style.content}>
      <div className={Style.content_overlay}  ></div>
      <img className={Style.image_card} src={image} alt=""/>
      <div className={`${Style.content_details} ${Style.fadeIn_bottom}`}>
      <i className="fa-solid fa-expand" style={{color:"white"}}></i>
      </div>
    
  </div>
</div>

     
    ));
  
  
    //function to show a specific image in the lightbox, amd make lightbox visible
    const showImage = (image) => {
      setImageToShow(image);
      setLightBoxDisplay(true);
    };
  
    //hide lightbox
    const hideLightBox = () => {
      setLightBoxDisplay(false);
    };
  
    //show next image in lightbox
    const showNext = (e) => {
      e.stopPropagation();
      let currentIndex = images.indexOf(imageToShow);
      if (currentIndex >= images.length - 1) {
        setLightBoxDisplay(false);
      } else {
        let nextImage = images[currentIndex + 1];
        setImageToShow(nextImage);
      }
    };
  
    //show previous image in lightbox
    const showPrev = (e) => {
      e.stopPropagation();
      let currentIndex = images.indexOf(imageToShow);
      if (currentIndex <= 0) {
        setLightBoxDisplay(false);
      } else {
        let nextImage = images[currentIndex - 1];
        setImageToShow(nextImage);
      }
    };
    
  
    return (
      <>
      <div className={Style.GaleryHeading}>

      <h2>Gallery</h2>
      </div>
    
        <div className={Style.app1}>
    
            {imageCards}</div>
        
        {
          lightboxDisplay ? 
          <div id={Style.lightbox} onClick={hideLightBox}>
            <button onClick={showPrev} className={Style.arrows}>⭠</button>
            <img id={Style.lightbox_img} src={imageToShow} alt=""></img>
            <button onClick={showNext} className={Style.arrows}>⭢</button>
          </div>
         : ""
        }
          <div className={Style.loadmore}>
    {imagesToShow < images.length && (
     <button onClick={handleLoadMore} className={Style.loadmore_btn}>Load More</button>
   )}
   </div>
      </>
    );
  }
  export default ImageGallery