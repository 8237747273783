import React from "react";
import Style from "./cntactForm.module.css";
import  { useState } from 'react';
import Modal from 'react-modal';
import "./form.css"
// import 'react-modal/style.css';
// import { useState } from "react";
// import 'react-modal/style.css';


const Index = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fname, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const isSubmitDisabled = !fname || !lName || !email || !message;

  const handleSubmit =async  (event) => {
    event.preventDefault();
    // Perform form submission actions
    
    const formData = new FormData();
    formData.append('firstname', fname);
    formData.append('lastname', lName);
    formData.append('email', email);
    formData.append('message', message);

    try {
      // Send the form data to the PHP script
      const response = await fetch('https://hussainjamil.com/form.php', {
        method: 'POST',
        body: formData
      });

      // Check the response status
      if (response.ok) {
        // Redirect to the desired page
        setIsModalOpen(true);
        // window.location.href = '/';
      } else {
        // Handle error case
        console.error('Form submission failed.');
      }
    } catch (error) {
      // Handle error case
      console.error('An error occurred while submitting the form.', error);
    }
  };
  const closeModal = () => {
    window.location.reload();
    setIsModalOpen(false);
  };
    
  // const [formData, setFormData] = useState({
  //   firstname: "",
  //   lastname: "",
  //   email: "",
  //   message: "",
  // });
  // const handleChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setFormData({ ...formData, [name]: value });
    
  //   console.log(formData);
  // };
  return (
    <div className={Style.formContainer}>
      <h2 className={Style.heading}>Let's Get in Touch</h2>
      <form className={Style.form} action="https://hussainjamil.com/form.php"  method="post" onSubmit={handleSubmit} >
        <label className={Style.form_label_heading} htmlFor="Name">
          Name <span className={Style.form_star}>*</span>
        </label>
        <div className={Style.First_part}>
          <div className={Style.input_size}>
            <input
              type="text"
              className={Style.input}
              // onChange={handleChange}
              //  value={formData.firstname}
              value={fname} onChange={(e) => setFName(e.target.value)}
               name="firstname"
            />
            <label htmlFor="firstname">First</label>
          </div>
          <div className={Style.input_size}>
            <input
              type="text"
              className={Style.input}
              // onChange={handleChange}
              // value={formData.lastname}
              name="lastname"
              value={lName} onChange={(e) => setLName(e.target.value)}
            />
            <label htmlFor="lastname">Last</label>
          </div>
        </div>
        <label className={Style.form_label_heading} htmlFor="Name">
          Email <span className={Style.form_star}>*</span>
        </label>
        <input type="email" className={Style.input} 
        // onChange={handleChange} 
        // value={formData.email}
        value={email} onChange={(e) => setEmail(e.target.value)}
        name="email" 
         />
        <label className={Style.form_label_heading} htmlFor="Name">
          Comment or Message <span className={Style.form_star}>*</span>
        </label>
        <textarea
          type="text"
          className={Style.textarea}
          // onChange={handleChange}
          // value={formData.message}
          value={message} onChange={(e) => setMessage(e.target.value)}
          name="message"
        />
        <button type="submit" name="submit"  disabled={isSubmitDisabled} className={isSubmitDisabled ? Style.disabled :Style.submit_btn}>
          Submit
        </button>


      </form>
      <Modal
         style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            width: '300px',
            margin: 'auto',
            padding: '20px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'white'
          }
        }}
       isOpen={isModalOpen} onRequestClose={closeModal}>
        <div style={{marginBottom:"50px", textAlign:"center"}}>
         <h2 className={Style.hussain}>Hussain Jamil</h2>
         <span className={Style.studio}>Studio</span>
         </div>
        <h2 style={{fontSize:"20px"}}>Your Email has been Sent Successfully</h2>
        
        <button onClick={closeModal}><i className="fa-solid fa-xmark"></i></button>
      </Modal>
    </div>
  );
};

export default Index;
