import React from "react";
// import Slider from "../Layouts/Slider"
import Style from "./sculptures.module.css";
// import ServiceContent from "../ServicesContent";
import SideBar from "../../Components/SideBar";
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import banner from "../../assets/51.jpg";
import Footer from "../../Components/Layouts/Footer";
import Cards from "../../Components/Cards";
import Cards2 from "../../Components/Cards2";

const index = () => {
  //   const list = [
  //     {
  //       value: "Made of high-quality metal for durability and longevity",
  //     },
  //     {
  //       value: "Available in a variety of sizes and finishes to suit your needs",
  //     },
  //     {
  //       value:
  //         "Easy to clean and maintain, ensuring long-lasting beauty and functionality",
  //     },
  //   ];
  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
        <ServiceBanner heading="Sculptures" img={banner} />
        <div className={Style.container}>
          <div className={Style.Products}>
            {/* <h1 className={Style.Products_heading}>
              The Beauty of Sculpture: Hussain Jamil's Artistic Wall Decor
            </h1> */}
            {/* <p>
              At Hussain Jamil, we are committed to creating the highest quality
              art pieces for our customers. We take great pride in our work and
              are constantly striving to push the boundaries of what is possible
              in the world of wall sculptures. Browse our collection today and
              discover the perfect wall sculpture to add a touch of elegance to
              your space. If you have any questions or need assistance in
              selecting the perfect piece, please don't hesitate to contact us.
            </p> */}
          </div>

          <Cards
            heading={"Metal Pillars"}
            // detail="Our metal pillar wall sculptures are a true work of art, with their intricate designs and stunning finishes. Made from high-quality materials, these sculptures are built to last and are sure to make a statement in any room."
            link={"/metal-pillars"}
            image="./images/Metal-Pillars/9.jpg"
          />
          <Cards2
            heading={"Metal Sculptures"}
            // detail="Welcome to the world of exquisite metal sculptures crafted by Hussain Jamil. These stunning pieces of art are the perfect way to add a touch of elegance and sophistication to any space. Our collection of wall sculptures features a range of materials, including metal pillars, reflective mirrors, and more."
            image="./images/6.jpg"
            link="/metal-sculpture"
          />
          {/* <Cards
         heading={"Scrambled circles"}
         detail="For a more abstract look, check out our scrambled circles. These unique pieces of art feature a playful combination of colors and textures that are sure to catch the eye and add a pop of personality to your space."
         image="./images/scrumble7.jpg"
         link="/scrumbled-circles"
        /> */}
          {/* <Cards2
         heading={"Canvas"}
         detail="Our collection of canvas art features a range of designs, including, abstract landscapes, and more. Each piece is handcrafted by Hussain Jamil using high-quality materials, ensuring that every canvas is a true work of art. Welcome to the world of stunning canvas art . These unique pieces are perfect for adding a pop of color and personality to any space."
         image="./images/Canvas/16.jpg"
         link="/canvas"
        /> */}
          {/* <Cards
         heading={"Reflective ovals"}
         detail="Our collection of reflective ovals features a range of designs, each handcrafted by Hussain Jamil using high-quality materials, ensuring that every piece is a true work of art.

         Our reflective ovals are perfect for adding a touch of elegance to your decor. These eye-catching pieces feature a unique oval shape with a reflective surface, creating a mesmerizing effect that catches the light and adds dimension to any room."
         image="./images/ovals12.jpg"
         link="/reflective-ovals"
        /> */}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default index;
