import React from 'react'
// import Slider from "../Layouts/Slider"
import Style from "./sitespecific.module.css"
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import SideBar from "../../Components/SideBar"
import banner from "../../assets/site1.webp";
import Footer from "../../Components/Layouts/Footer"
import Gallery from "../../Components/GallerySection/SiteSpecificGallery" 
const index = () => {
  const image1 =
"./images/sitespecifcwebp/1.webp";
const image2 =
"./images/sitespecifcwebp/2.webp";
const image3 =
"./images/sitespecifcwebp/3.webp";
const image4 =
"./images/sitespecifcwebp/4.webp";
const image5 =
"./images/sitespecifcwebp/5.webp";
const image6 =
"./images/sitespecifcwebp/6.webp";
const image7 =
"./images/sitespecifcwebp/7.webp";
const image8 =
"./images/sitespecifcwebp/8.webp";
const image9 =
"./images/sitespecifcwebp/9.webp";
const image10 =
"./images/sitespecifcwebp/10.webp";
const image11 =
"./images/sitespecifcwebp/11.webp";
const image12 =
"./images/sitespecifcwebp/12.webp";
const image13 =
"./images/sitespecifcwebp/13.webp";
const image14 =
"./images/sitespecifcwebp/14.webp";
const image15 =
"./images/sitespecifcwebp/15.webp";
const image16 =
"./images/sitespecifcwebp/16.webp";




const image17 =
"./images/sitespecifcwebp/17.webp";
const image18 =
"./images/sitespecifcwebp/18.webp";
const image19 =
"./images/sitespecifcwebp/19.webp";
const image20 =
"./images/sitespecifcwebp/20.webp";
const image21 =
"./images/sitespecifcwebp/21.webp";
const image22 =
"./images/sitespecifcwebp/22.webp";
const image23 =
"./images/sitespecifcwebp/23.webp";
const image24 =
"./images/sitespecifcwebp/24.webp";
const image25 =
"./images/sitespecifcwebp/25.webp";

const image26 =
"./images/sitespecifcwebp/26.webp";
const image27 =
"./images/sitespecifcwebp/27.webp";
const image28 =
"./images/sitespecifcwebp/28.webp";
const image29 =
"./images/sitespecifcwebp/29.webp";
const image30 =
"./images/sitespecifcwebp/30.webp";
const image31 =
"./images/sitespecifcwebp/31.webp";
// const image32 =
// "./images/sitespecifcwebp/32.webp";








const images = [
  image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
  image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31

 
];
  return (
    <div className={Style.flex} >
 
<SideBar/>



   <div className={Style.flex_Slider}>
    <ServiceBanner
      heading="Site Specific"
    
      img={banner}
    />
    {/* <Slider /> */}
   
   
    <Gallery images={images}/>
    {/* <FourthSection/> */}
   
    <Footer/>
    </div>
   </div>
  )
}

export default index