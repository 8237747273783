import React from "react";
// import Slider from "../Layouts/Slider"
import Style from "./ReflectiveMirror.module.css";
// import ServiceContent from "../../Components/ServicesContent";
import SideBar from "../../Components/SideBar";
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import banner from "../../assets/reflective mirror.jpg";
import Footer from "../../Components/Layouts/Footer";
// import Content from "../../Components/Content";
// import SubProducts from "../../Components/subProducts";
// import ReflectiveMirrorGallery from "../../Components/GallerySection/ReflectiveMirrorGallery";
import Gallery from "../../Components/GallerySection/SiteSpecificGallery"


const index = () => {
  const image1 =
  "./images/reflectivemirrorwebp/1.webp";
  const image2 =
  "./images/reflectivemirrorwebp/2.webp";
  const image3 =
  "./images/reflectivemirrorwebp/3.webp";
  const image4 =
  "./images/reflectivemirrorwebp/4.webp";
  const image5 =
  "./images/reflectivemirrorwebp/5.webp";
  const image6 =
  "./images/reflectivemirrorwebp/6.webp";
  const image7 =
  "./images/reflectivemirrorwebp/7.webp";
  const image8 =
  "./images/reflectivemirrorwebp/8.webp";
  const image9 =
  "./images/reflectivemirrorwebp/9.webp";
  const image10 =
  "./images/reflectivemirrorwebp/10.webp";
  const image11 =
  "./images/reflectivemirrorwebp/11.webp";
  const image12 =
  "./images/reflectivemirrorwebp/12.webp";
  const image13 =
  "./images/reflectivemirrorwebp/13.webp";
  const image14 =
  "./images/reflectivemirrorwebp/14.webp";
  const image15 =
  "./images/reflectivemirrorwebp/15.webp";
  const image16 =
  "./images/reflectivemirrorwebp/16.webp";
  const image17 =
"./images/reflectivemirrorwebp/17.webp";
const image18 =
"./images/reflectivemirrorwebp/18.webp";
const image19 =
"./images/reflectivemirrorwebp/19.webp";
const image20 =
"./images/reflectivemirrorwebp/20.webp";
const image21 =
"./images/reflectivemirrorwebp/21.webp";
const image22 =
"./images/reflectivemirrorwebp/22.webp";
const image23 =
"./images/reflectivemirrorwebp/23.webp";
const image24 =
"./images/reflectivemirrorwebp/24.webp";
const image25 =
"./images/reflectivemirrorwebp/25.webp";

const image26 =
"./images/reflectivemirrorwebp/26.webp";
const image27 =
"./images/reflectivemirrorwebp/27.webp";
const image28 =
"./images/reflectivemirrorwebp/28.webp";
const image29 =
"./images/reflectivemirrorwebp/29.webp";
const image30 =
"./images/reflectivemirrorwebp/30.webp";
const image31 =
"./images/reflectivemirrorwebp/31.webp";



const image32 =
"./images/reflectivemirrorwebp/32.webp";
const image33 =
"./images/reflectivemirrorwebp/33.webp";
const image34 =
"./images/reflectivemirrorwebp/34.webp";
const image35 =
"./images/reflectivemirrorwebp/35.webp";
const image36 =
"./images/reflectivemirrorwebp/36.webp";
const image37 =
"./images/reflectivemirrorwebp/37.webp";
const image38 =
"./images/reflectivemirrorwebp/38.webp";
const image39 =
"./images/reflectivemirrorwebp/39.webp";
const image40 =
"./images/reflectivemirrorwebp/40.webp";
const image41 =
"./images/reflectivemirrorwebp/41.webp";
const image42 =
"./images/reflectivemirrorwebp/42.webp";
const image43 =
"./images/reflectivemirrorwebp/43.webp";
const image44 =
"./images/reflectivemirrorwebp/44.webp";
const image45 =
"./images/reflectivemirrorwebp/45.webp";
const image46 =
"./images/reflectivemirrorwebp/46.webp";
const image47 =
"./images/reflectivemirrorwebp/47.webp";
const image48 =
"./images/reflectivemirrorwebp/48.webp";
const image49 =
"./images/reflectivemirrorwebp/49.webp";
const image50 =
"./images/reflectivemirrorwebp/50.webp";
const image51 =
"./images/reflectivemirrorwebp/51.webp";
const image52 =
"./images/reflectivemirrorwebp/52.webp";
const image53 =
"./images/reflectivemirrorwebp/53.webp";
const image54 =
"./images/reflectivemirrorwebp/54.webp";
const image55 =
"./images/reflectivemirrorwebp/55.webp";
const image56 =
"./images/reflectivemirrorwebp/56.webp";

const image57 =
"./images/reflectivemirrorwebp/57.webp";
const image58 =
"./images/reflectivemirrorwebp/58.webp";
const image59 =
"./images/reflectivemirrorwebp/59.webp";
const image60 =
"./images/reflectivemirrorwebp/60.webp";
const image61 =
"./images/reflectivemirrorwebp/61.webp";
const image62 =
"./images/reflectivemirrorwebp/62.webp";
const image63 =
"./images/reflectivemirrorwebp/63.webp";
const image64 =
"./images/reflectivemirrorwebp/64.webp";

const image65 =
"./images/reflectivemirrorwebp/65.webp";
const image66 =
"./images/reflectivemirrorwebp/66.webp";
const image67 =
"./images/reflectivemirrorwebp/67.webp";
const image68 =
"./images/reflectivemirrorwebp/68.webp";
const image69 =
"./images/reflectivemirrorwebp/69.webp";
const image70 =
"./images/reflectivemirrorwebp/70.webp";

  const images = [image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
    image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,
  image32,image33,image34,image35,image36,image37,image38,image39,image40,image41,image42,image43,image44,image45,image46,image47,image48,image49,image50,image51,image52,image53,image54,image55,image56,image57,image58,image59,image60,image61,image62,image63,image64,image65,image66,image67,image68,image69,image70
  ];
  // const list = [
  //   {
  //     value: "Available in a variety of sizes and shapes to suit your needs",
  //   },
  //   {
  //     value: "Made with high-quality materials for durability and longevity",
  //   },
  //   {
  //     value:
  //       "Easy to clean and maintain, ensuring long-lasting beauty and functionality",
  //   },
  // ];
  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
        <ServiceBanner
          heading="Reflective Mirrors"
          // detail={
          //   "Add a touch of elegance to your space with our Reflective Mirrors. These mirrors are not only functional, but also serve as beautiful decorative pieces, making them a great addition to any room in your home or office. If you're looking for a stylish and functional mirror, our Reflective Mirrors are the perfect choice. With their sleek design, high-quality materials, and range of sizes and shapes, these mirrors are sure to add a touch of elegance to any room in your home or office."
          // }
          img={banner}
        />

        <div className={Style.container}>
          {/* <ServiceContent list={list} listHeading="Features:" /> */}
          <div className={Style.products}>
            {/* 1 */}
            {/* <SubProducts
              image={"./images/Fineries1.jpg"}
              heading="Title : "
              detail={"Fineries"}
              heading2="Dimension : "
              detail2={" 244 x 60cm each piece "}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Gold tint"}
            /> */}
            {/* 2 */}
            {/* <SubProducts
              image={"./images/Missing year1.jpg"}
              heading="Title : "
              detail={"Missing year"}
              heading2="Dimension : "
              detail2={"60 x 60cm each piece"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Gold tint "}
            /> */}
            {/* 3 */}
            {/* <SubProducts
              image={"./images/Twin Tower.jpg"}
              heading="Title : "
              detail={" Twin Tower"}
              heading2="Dimension : "
              detail2={" 244 x 122cm each piece"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Gold tint"}
            /> */}
            {/* 4 */}
            {/* <SubProducts
              image={"./images/Contemporary1.jpg"}
              heading="Title : "
              detail={"Contemporary"}
              heading2="Dimension : "
              detail2={"244 x 122cm"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={" Rose gold tint"}
            /> */}
            {/* 5 */}
            {/* <SubProducts
              image={"./images/Crinkle.jpg"}
              heading="Title : "
              detail={"Crinkle"}
              heading2="Dimension : "
              detail2={"122 x 244cm"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={" Silver tint"}
            /> */}
          </div>
          {/* <Content
            heading={"Design and Materials: "}
            detail="Our Reflective Mirrors are designed with both style and functionality in mind. The sleek and modern design complements any interior decor, and the high-quality materials used ensure that the mirrors will last for years to come. We use only the finest quality materials in the construction of our Reflective Mirrors. Each mirror is crafted from high-quality glass and metal, ensuring that it will stand up to daily use and maintain its beauty over time."
            img="./images/3.jpg"
          /> */}

          {/* <Content
            heading={"Shapes and Sizes: "}
            detail={
              "Our Reflective Mirrors are available in a variety of shapes and sizes, making it easy to find the perfect mirror for your space. Choose from round, square, rectangular, and oval shapes, and a range of sizes from small to large."
            }
            img="./images/51.jpg"
          /> */}
           {/* <h2 className={Style.heading_bottom}>Image's Gallery</h2> */}
           <Gallery images={images}/>

          {/* <ReflectiveMirrorGallery /> */}
        
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default index;
