import React from "react";
// import Slider from "../Layouts/Slider"
import Style from "./wallmount.module.css";
// import ServiceContent from "../ServicesContent";
import SideBar from "../../Components/SideBar";
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import banner from "../../assets/51.jpg";
import Footer from "../../Components/Layouts/Footer";
import Cards from "../../Components/Cards";
import Cards2 from "../../Components/Cards2";

const index = () => {
  //   const list = [
  //     {
  //       value: "Made of high-quality metal for durability and longevity",
  //     },
  //     {
  //       value: "Available in a variety of sizes and finishes to suit your needs",
  //     },
  //     {
  //       value:
  //         "Easy to clean and maintain, ensuring long-lasting beauty and functionality",
  //     },
  //   ];
  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
        <ServiceBanner heading="Wall Mounts" img={banner} />
        <div className={Style.container}>
          <div className={Style.Products}>
            {/* <h1 className={Style.Products_heading}>
              The Beauty of Sculpture: Hussain Jamil's Artistic Wall Decor
            </h1>
            <p>
              At Hussain Jamil, we are committed to creating the highest quality
              art pieces for our customers. We take great pride in our work and
              are constantly striving to push the boundaries of what is possible
              in the world of wall sculptures. Browse our collection today and
              discover the perfect wall sculpture to add a touch of elegance to
              your space. If you have any questions or need assistance in
              selecting the perfect piece, please don't hesitate to contact us.
            </p> */}
          </div>

          {/* <Cards
        heading={"Metal Pillars"}
        detail="Our metal pillar wall sculptures are a true work of art, with their intricate designs and stunning finishes. Made from high-quality materials, these sculptures are built to last and are sure to make a statement in any room."
        link={"/metal-pillars"}
        image="./images/Metal-Pillars/9.jpg"
        /> */}
          <Cards2
            heading={"Reflective mirrors"}
            // detail="Our reflective mirrors are perfect for adding a touch of glamour to your decor. These eye-catching pieces feature a range of shapes and sizes, including reflective ovals, allowing you to create a truly customized look for your home or office."
            image="./images/Reflective-mirrors/4.jpg"
            link="/reflective-mirror"
          />
          <Cards
            heading={"Scrambled circles"}
            // detail="For a more abstract look, check out our scrambled circles. These unique pieces of art feature a playful combination of colors and textures that are sure to catch the eye and add a pop of personality to your space."
            image="./images/scrumble7.jpg"
            link="/scrumbled-circles"
          />
          <Cards2
            heading={"Metal Drawings"}
            // detail=" Elevate your space with our stunning metal drawings. These intricate pieces are crafted with precision and attention to detail, making them true works of art. From abstract designs to geometric patterns, our metal drawings are perfect for adding a touch of modern elegance to any interior design. Hang them alone or create a gallery wall for a truly striking display."
            image="./images/metaldrwaningwebp/9.webp"
            link="/metal-drawings"
          />
          <Cards
            heading={"Digital Prints"}
            // detail=" Our digital prints are the perfect way to add personality and style to any room. With a wide range of designs and colors to choose from, you're sure to find the perfect piece to suit your space. Each print is produced with the highest quality materials, ensuring that your artwork will look stunning for years to come. Whether you're looking to create a statement wall or simply add a pop of color, our digital prints are the ideal choice."
            image="./images/digitalprintwebp/12.webp"
            link="/digital-prints"
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default index;
