import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./slider2.css"



const colors = [
  
  {
  class:"slide1",
    heading:`NFTs`,
    // detail:` as when we see words in mirrors and realise it`,
  //  detail2:` through the inverted image."`,
   link:"/nfts",
   linkname:"Explore"
},
{
  class:"slide",
  heading:"Site Specific",
  // detail:"The trikery of mirror surfaces and the materials ",
  // detail2:"inherent quality of literal inversion.",
  link:"/site-specific",
  linkname:"Explore"
   
},
{
    
    class:"slide2",
    heading:"Wall Mounts",
    // detail:"and awe one feels at seeing in the mirror at the start ",
    // detail2:"of every day intrigued me to explore the nature of reflections.",
    // link:"/nfts",
    // linkname:"NFTs",
    explore:"explore"
},
{
    
  class:"slide3",
  heading:"Sculptures",
  // detail:"From abstract forms to figurative designs, ",
  // detail2:"our sculptures are the perfect addition to any art collection.",
  // link:"/sculpture",
  // linkname:"Sculpture",
  explore:"explore"
}
];

const delay = 8000;


function Slideshow() {
  const [index, setIndex] = useState(0);
  const [activeExplore, setActiveExplore] =useState(null);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    setActiveExplore(null); 
    return () => {
      resetTimeout();
    };
  }, [index]);

  const handleExploreClick = (slideIndex) => {
    setActiveExplore(slideIndex);
  };

  return (
    <div className="slideshow" id={"top"}>
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((slide, idx) => (
          <div className={slide.class} key={idx}>
            <div className="box_outer">
              <div className={`slider_box`}>
                <h1 className="slider_heading">{slide.heading}</h1>
                <p className="slider_content">
                  {slide.detail}
                  <br />
                  {slide.detail2}
                </p>
                {idx === colors.length - 1 || idx === colors.length - 2 ? (
                  <div className="btn_div">
                    <button
                      className={`btn${activeExplore === idx ? " active" : ""}`}
                      onClick={() => handleExploreClick(idx)}
                    >
                    <span>{slide.explore}<i className="fa-solid fa-arrow-down" style={{marginLeft:"5px"}}></i></span>  
                    </button>
                  </div>
                ) : (
                  <div className="btn_div">
                    <Link className={"btn"} to={slide.link}>
                      <span>{slide.linkname}</span>
                    </Link>
                  </div>
                )}
                  {activeExplore !== null && (
        <div className="explore-details">
          {/* Render the appropriate content based on the activeExplore state */}
          {activeExplore === colors.length - 1 ? (
            <div className="expolore_links">
              {/* Add your content for the last slide's details */}
              <Link to={"/metal-pillars"}>Metal Pillars</Link>
              <Link to={"metal-sculpture"}>Metal Sculptures</Link>
            </div>
          ) : (
            <div className="expolore_links">
              
              {/* Add your content for the second last slide's details */}
              <Link to={"/reflective-mirror"}>Reflective Mirrors</Link>
              <Link to={"/scrumbled-circles"}>Scrambled Circles</Link>
              <Link to={"/metal-drawings"}>Metal Drawings</Link>
              <Link to={"/digital-prints"}>Digital Prints</Link>
            </div>
          )}
        </div>
      )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>

    
    </div>
  );
}

export default Slideshow
