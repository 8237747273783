import React from 'react'
import Style from "./footer.module.css"
import { Link } from 'react-router-dom'
const index = () => {
  return (
    <div className={Style.footer}>

<div>
    <ul className={Style.footer_icon_ul}>
    <li className={Style.footer_icons_li}>
                  <Link className={Style.footer_link} to="https://www.facebook.com/Hussain-Jamil-105076446550272/about/" target={"facebook"}><i className={`fa-brands fa-facebook-f ${Style.footer_icons}`}></i></Link>

                  


              </li>
              <li className={Style.footer_icons_li}>
                  <Link className={Style.footer_link} to="https://twitter.com/Hucainn" target={"twitter"}><i className={`fa-brands fa-twitter ${Style.footer_icons}`}></i></Link>


                  
              </li>
              <li className={Style.footer_icons_li}>
                  <Link className={Style.footer_link} to="https://www.instagram.com/hussainjamilstudio/" target={"instagram"}><i className={`fa-brands fa-instagram ${Style.footer_icons}`}></i></Link>


              </li>

</ul>
</div>
<div className={Style.footer_inner}>
    <Link to='/'>
<h1 className={Style.footer_name}>Hussain Jamil
<span>STUDIO</span>
</h1>
</Link>

</div>
<div>
    <Link to='mailto:hussainjamilstudio@gmail.com' className={Style.footer_link} ><p>hussainjamilstudio@gmail.com</p></Link>
</div>


    </div>
  )
}

export default index