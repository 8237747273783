import React from "react";

import Style from "./404.module.css";

import SideBar from "../../Components/SideBar";

import { Link } from "react-router-dom";
import Footer from "../../Components/Layouts/Footer";



const index = () => {







  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
       

        <div className={Style.page404}>
          <div  className={Style.pagetext}>
          <p>4<span >0</span>4</p>
          </div>
      <div>
        <Link className={Style.homeLink} to={"/"}>Go To Home</Link>
      </div>
      
        
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default index;
