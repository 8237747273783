import React from 'react'
// import Slider from "../Layouts/Slider"
import Style from "./nft.module.css"
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import SideBar from "../../Components/SideBar"
import banner from "../../assets/b1.webp";
import Footer from "../../Components/Layouts/Footer"
import Gallery from "../../Components/GallerySection/NftGallery" 
const index = () => {
  const image1 =
"./images/webpnft/h1.webp";
const image2 =
"./images/webpnft/h2.webp";
const image3 =
"./images/webpnft/h3.webp";
const image4 =
"./images/webpnft/h4.webp";
const image5 =
"./images/webpnft/h5.webp";
const image6 =
"./images/webpnft/h6.webp";
const image7 =
"./images/webpnft/h7.webp";
const image8 =
"./images/webpnft/h8.webp";
const image9 =
"./images/webpnft/h9.webp";
const image10 =
"./images/webpnft/h10.webp";
const image11 =
"./images/webpnft/i1.webp";
const image12 =
"./images/webpnft/i2.webp";
const image13 =
"./images/webpnft/i3.webp";
const image14 =
"./images/webpnft/i4.webp";
const image15 =
"./images/webpnft/i5.webp";
const image16 =
"./images/webpnft/i6.webp";




const image18 =
"./images/webpnft/i7.webp";
const image19 =
"./images/webpnft/i9.webp";
const image20 =
"./images/webpnft/i10.webp";
const image21 =
"./images/webpnft/j1.webp";
const image22 =
"./images/webpnft/j2.webp";
const image23 =
"./images/webpnft/j3.webp";
const image24 =
"./images/webpnft/j4.webp";
const image25 =
"./images/webpnft/j5.webp";
const image26 =
"./images/webpnft/j6.webp";
const image27 =
"./images/webpnft/j7.webp";
const image28 =
"./images/webpnft/j8.webp";
const image29 =
"./images/webpnft/j9.webp";
const image30 =
"./images/webpnft/j10.webp";
const image31 =
"./images/webpnft/k1.webp";
const image32 =
"./images/webpnft/k2.webp";
const image33 =
"./images/webpnft/k3.webp";



const image35 =
"./images/webpnft/k4.webp";
const image36 =
"./images/webpnft/k5.webp";
const image37 =
"./images/webpnft/k6.webp";
const image38 =
"./images/webpnft/k7.webp";
const image39 =
"./images/webpnft/k8.webp";
const image40 =
"./images/webpnft/k9.webp";
const image41 =
"./images/webpnft/k10.webp";
const image42 =
"./images/webpnft/l1.webp";
const image43 =
"./images/webpnft/l2.webp";
const image44 =
"./images/webpnft/l3.webp";
const image45 =
"./images/webpnft/l4.webp";
const image46 =
"./images/webpnft/l5.webp";
const image47 =
"./images/webpnft/l6.webp";
const image48 =
"./images/webpnft/l7.webp";
const image49 =
"./images/webpnft/l8.webp";
const image50 =
"./images/webpnft/l9.webp";



const image51 =
"./images/webpnft/l10.webp";
const image52 =
"./images/webpnft/m1.webp";
const image53 =
"./images/webpnft/m2.webp";
const image54 =
"./images/webpnft/m3.webp";
const image55 =
"./images/webpnft/m4.webp";
const image56 =
"./images/webpnft/m5.webp";
const image57 =
"./images/webpnft/m6.webp";
const image58 =
"./images/webpnft/m7.webp";
const image59 =
"./images/webpnft/m8.webp";
const image60 =
"./images/webpnft/m9.webp";
const image61 =
"./images/webpnft/m10.webp";
const image62 =
"./images/webpnft/n1.webp";
const image63 =
"./images/webpnft/n2.webp";
const image64 =
"./images/webpnft/n3.webp";
const image65 =
"./images/webpnft/n4.webp";
const image66 =
"./images/webpnft/n5.webp";




const image67 =
"./images/webpnft/n6.webp";
const image68 =
"./images/webpnft/n7.webp";
const image69 =
"./images/webpnft/n8.webp";
const image70 =
"./images/webpnft/n9.webp";
const image71 =
"./images/webpnft/n10.webp";
const image72 =
"./images/webpnft/o1.webp";
const image73 =
"./images/webpnft/o2.webp";
const image74 =
"./images/webpnft/o3.webp";
const image75 =
"./images/webpnft/o4.webp";
const image76 =
"./images/webpnft/o5.webp";
const image77 =
"./images/webpnft/o6.webp";
const image78 =
"./images/webpnft/o7.webp";
const image79 =
"./images/webpnft/o9.webp";
const image80 =
"./images/webpnft/a1.webp";
const image81 =
"./images/webpnft/a2.webp";
const image82 =
"./images/webpnft/a3.webp";



const image83 =
"./images/webpnft/a4.webp";
const image84 =
"./images/webpnft/a4.webp";
const image85 =
"./images/webpnft/a5.webp";
const image86 =
"./images/webpnft/a6.webp";
const image87 =
"./images/webpnft/a7.webp";
const image88 =
"./images/webpnft/a8.webp";
const image89 =
"./images/webpnft/a9.webp";
const image90 =
"./images/webpnft/a10.webp";
const image91 =
"./images/webpnft/a11.webp";
const image92 =
"./images/webpnft/b1.webp";
const image93 =
"./images/webpnft/b2.webp";
const image94 =
"./images/webpnft/b3.webp";
const image95 =
"./images/webpnft/b4.webp";
const image96 =
"./images/webpnft/b5.webp";
const image97 =
"./images/webpnft/b6.webp";
const image98 =
"./images/webpnft/b7.webp";


const image99 =
"./images/webpnft/b8.webp";
const image100 =
"./images/webpnft/b9.webp";
const image101 =
"./images/webpnft/b10.webp";
const image102 =
"./images/webpnft/c1.webp";
const image103 =
"./images/webpnft/c2.webp";
const image104 =
"./images/webpnft/c3.webp";
const image105 =
"./images/webpnft/c4.webp";
const image106 =
"./images/webpnft/c5.webp";
const image107 =
"./images/webpnft/c6.webp";
const image108 =
"./images/webpnft/c7.webp";
const image109 =
"./images/webpnft/c8.webp";
const image110 =
"./images/webpnft/c9.webp";
const image111 =
"./images/webpnft/c10.webp";
const image112 =
"./images/webpnft/d1.webp";
const image113 =
"./images/webpnft/d2.webp";
const image114=
"./images/webpnft/d3.webp";


const image115 =
"./images/webpnft/d4.webp";
const image116 =
"./images/webpnft/d5.webp";
const image117 =
"./images/webpnft/d6.webp";
const image118 =
"./images/webpnft/d7.webp";
const image119 =
"./images/webpnft/d8.webp";
const image120 =
"./images/webpnft/d9.webp";
const image121 =
"./images/webpnft/d10.webp";
const image122 =
"./images/webpnft/e1.webp";
const image123 =
"./images/webpnft/e2.webp";
const image124 =
"./images/webpnft/e3.webp";
const image125 =
"./images/webpnft/e4.webp";
const image126 =
"./images/webpnft/e5.webp";
const image127 =
"./images/webpnft/e6.webp";
const image128 =
"./images/webpnft/e7.webp";
const image129 =
"./images/webpnft/e8.webp";
const image130=
"./images/webpnft/e9.webp";



const image131 =
"./images/webpnft/e10.webp";
const image132 =
"./images/webpnft/f1.webp";
const image133 =
"./images/webpnft/f2.webp";
const image134 =
"./images/webpnft/f3.webp";
const image135 =
"./images/webpnft/f4.webp";
const image136 =
"./images/webpnft/f5.webp";
const image137 =
"./images/webpnft/f6.webp";
const image138 =
"./images/webpnft/f7.webp";
const image139 =
"./images/webpnft/f8.webp";
const image140 =
"./images/webpnft/f10.webp";
const image141 =
"./images/webpnft/f11.webp";
const image142 =
"./images/webpnft/f12.webp";
const image143 =
"./images/webpnft/f13.webp";
const image144 =
"./images/webpnft/g1.webp";
const image145 =
"./images/webpnft/g2.webp";
const image146=
"./images/webpnft/g3.webp";



const image147 =
"./images/webpnft/g4.webp";
const image148 =
"./images/webpnft/g5.webp";
const image149 =
"./images/webpnft/g6.webp";
const image150 =
"./images/webpnft/g7.webp";
const image151 =
"./images/webpnft/g8.webp";
const image152 =
"./images/webpnft/g9.webp";
// const image153 =
// "./images/webpnft/f13.webp";
// const image154 =
// "./images/webpnft/h10.webp";
// const image155 =
// "./images/webpnft/h10.webp";
// const image156 =
// "./images/webpnft/i10.webp";
// const image157 =
// "./images/webpnft/j10.webp";
// const image158 =
// "./images/webpnft/k10.webp";
// const image159 =
// "./images/webpnft/l10.webp";
// const image160 =
// "./images/webpnft/m10.webp";
// const image161 =
// "./images/webpnft/n10.webp";
// const image162=
// "./images/webpnft/f11.webp";

const images = [
  image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
  image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,image32,image33,
  image35,image36,image37,image38,image38,image39,image40,image41,image42,image43,image44,image45,image46,image47,image48,image49,image50,
  image51,image52,image53,image54,image55,image56,image57,image58,image59,image60,image61,image62,image63,image64,image65,image66,
  image67,image68,image69,image70,image71,image72,image73,image74,image75,image76,image77,image78,image79,image80,image81,image82,
  image83,image84,image85,image86,image87,image88,image89,image90,image91,image92,image93,image94,image95,image96,image97,image98,
  image99,image100,image101,image102,image103,image104,image105,image106,image107,image108,image109,image110,image111,image112,image113,image114,
  image115,image116,image117,image118,image119,image120,image121,image122,image123,image124,image125,image126,image127,image128,image129,image130,
  image131,image132,image133,image134,image135,image136,image137,image138,image139,image140,image141,image142,image143,image144,image145,image146,
  image147,image148,image149,image150,image151,image152,
];
  return (
    <div className={Style.flex} >
 
<SideBar/>



   <div className={Style.flex_Slider}>
    <ServiceBanner
      heading="NFTs"
    
      img={banner}
    />
    {/* <Slider /> */}
   
   
    <Gallery images={images}/>
    {/* <FourthSection/> */}
   
    <Footer/>
    </div>
   </div>
  )
}

export default index