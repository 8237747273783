import React from "react";
import { useState } from "react";
import Style from "./sidebar.module.css";
import { Link } from "react-router-dom";

const Index = () => {
  const menu_btn = () => {
    document.getElementById("side_menu").style.display = "block";
  };
  const hideMenu = () => {
    document.getElementById("side_menu").style.display = "none";
  };


  const [isDropmenuOpen, setIsDropmenuOpen] = useState(false);
  const [isDropmenuOpen2, setIsDropmenuOpen2] = useState(false);

  // Handle the click event on the icon
  const handleIconClick = () => {
    setIsDropmenuOpen(!isDropmenuOpen);

    // document.getElementById("icons").style.transform = "rotate(180deg)"
  };
  const handleIconClick2 = () => {
    setIsDropmenuOpen2(!isDropmenuOpen2);
    // document.getElementById("icons").style.transform = "rotate(180deg)"
  };
  return (
    <>
      <i className={`fa-solid fa-bars ${Style.menu_open}`} onClick={menu_btn}></i>
      <div className={Style.flex_Size} id="side_menu">
        <i className={`fa-solid fa-xmark ${Style.cancel}`} onClick={hideMenu}></i>
        <div className={Style.first_section_justify}>
          <Link className={Style.first_section_link} to="/">
            <h2>Hussain Jamil</h2>
          </Link>
          <span>Studio</span>
          <div className={Style.sideLinks}>
            <ul>
          
              <li>
                <Link to={"/nfts"}><p>NFTs</p></Link>
              </li>
              <li>
                <Link to={"/site-specific"}><p>Site specific</p></Link>
              </li>
              <li>
                <Link to={"/wall-mounts"}><p>Wall mounts</p></Link>
                <i className={`fa-solid fa-circle-chevron-down ${Style.dropdown_icon} ${isDropmenuOpen2 ? Style.rotate_icon : ''}`} onClick={handleIconClick2} ></i>
              </li>
              {isDropmenuOpen2 && (
                <ul className={Style.submenu}>
                  <li>
                    <Link  className={Style.submenu_links} to={"/reflective-mirror"}><p>Reflective mirrors</p></Link>
                
                  </li>
                  <li>
                    <Link className={Style.submenu_links} to={"/scrumbled-circles"}><p>Scrambled circles</p></Link>
                
                  </li>
                  <li>
                    <Link className={Style.submenu_links} to={"/metal-drawings"}><p>Metal drawings</p></Link>
                
                  </li>
                  <li>
                    <Link className={Style.submenu_links} to={"/digital-prints"}><p>Digital prints</p></Link>
                
                  </li>
                </ul>
                )}
                    <li>
                <Link to={"/sculptures"}><p>Sculptures</p> </Link>
                <i className={`fa-solid fa-circle-chevron-down ${Style.dropdown_icon} ${isDropmenuOpen ? Style.rotate_icon : ''}`} onClick={handleIconClick} id="icons"></i>
             
                {/* {`fas fa-bars ${isSubmenu1Open ? 'rotate-icon' : ''}`} */}

              </li>
              {isDropmenuOpen && (
                <ul className={Style.submenu}>
                  <li>
                    <Link className={Style.submenu_links} to={"/metal-pillars"}><p>Metal pillars</p></Link>
                   
                  </li>
                  <li>
                    <Link className={Style.submenu_links} to={"/metal-sculpture"}><p>Metal sculptures</p></Link>
                   
                  </li>
                </ul>
                )}
            </ul>
          </div>
          <div className={Style.icons_section}>
            <ul className={Style.icon_ul}>
              <li>
                <a
                  href="https://www.facebook.com/Hussain-Jamil-105076446550272/about/"
                  target={"blank"}
                >
                  <i className={`fa-brands fa-facebook-f ${Style.icons}`}></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/Hucainn" target={"blank"}>
                  <i className={`fa-brands fa-twitter ${Style.icons}`}></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/hussainjamilstudio/"
                  target={"blank"}
                >
                  <i className={`fa-brands fa-instagram ${Style.icons}`}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
