import React from 'react'
import FirstSection from "../LandingPage"
import { BrowserRouter ,Routes ,Route } from 'react-router-dom'
import PillerMetal from "../PillersMetal"
import ReflectiveMirror from "../ReflectiveMirror"
// import Canvas from "../Canvas"
import ScrumbledCircles from "..//ScrumbledCircles"
// import ReflectiveOvals from "../ReflectiveOvals"
import WallSculptures from "../wallSculptures"
// import SiteBasedProjects from "../SiteBasedProjects"
import NFT from "../NFT"
import SiteSpecific from "../SiteSpecific"
import MetalDrawing from "../MetalDrawings"
import MetalSculpture from "../MetalSculpture"
import DigitalPrints from "..//DigitalPrints"
import WallMounts from "../WallMounts"
import Error from "../404"

const index = () => {
  return (
    <BrowserRouter>
    <Routes>
      
      <Route  path='/' element={<FirstSection/>}/>
      <Route path='/metal-pillars' element={<PillerMetal/>}/>
      <Route path='/reflective-mirror' element={<ReflectiveMirror/>}/>
      {/* <Route path='/canvas' element={<Canvas/>}/> */}
      <Route path='/scrumbled-circles' element={<ScrumbledCircles/>}/>
      {/* <Route path='/reflective-ovals' element={<ReflectiveOvals/>}/> */}
      <Route path='/sculptures' element={<WallSculptures/>}/>
      {/* <Route path='/site-based-projects' element={<SiteBasedProjects/>}/> */}
      <Route path='/nfts' element={<NFT/>}/>
      <Route path='/site-specific' element={<SiteSpecific/>}/>
      <Route path='/metal-drawings' element={<MetalDrawing/>}/>
      <Route path='/metal-sculpture' element={<MetalSculpture/>}/>
      <Route path='/digital-prints' element={<DigitalPrints/>}/>
      <Route path='/wall-mounts' element={<WallMounts/>}/>
      <Route path='*' element={<Error/>}/>


      
    </Routes>
    
    </BrowserRouter>
  )
}

export default index