// import logo from './logo.svg';
// import './App.css';
import HomePage from "./pages/PagesRouting"

function App() {
  return (
   <>
   <HomePage/>
   </>
  );
}

export default App;
