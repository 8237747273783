import React from 'react'
import { Link } from 'react-router-dom'
import Style from "./cards.module.css"

const index = ({image,heading,detail,link}) => {
  return (
    <div className={Style.card}>
        <div className={Style.img_section}>
        <img src={image} alt=''/>
        </div>
        <div className={Style.content_section}>
        <h3 className={Style.product_heading}>{heading}</h3>
        <p className={Style.product_detail}>{detail}</p>
        <div className={Style.link_section}>
            <Link to={link} className={Style.redirect}>View More</Link>
        </div>
        </div>
    </div>
  )
}

export default index