import React from 'react'
// import Slider from "../Layouts/Slider"
import Style from "./style.module.css"
import Slider from "../../Components/Layouts/Slider/index"
import SideBar from "../../Components/SideBar"
import SecondSection from "../../Components/About"
// import Gallery from '../../Components/GallerySection'
// import FourthSection from "../../Components/WhatIDO"
import ContactForm from "../../Components/ContactForm"
import Footer from "../../Components/Layouts/Footer"
import Gallery from "../../Components/GallerySection/SiteSpecificGallery"
const index = () => {
  const image1 =
  "./images/metalpillerwebp/1.webp";
  const image2 =
  "./images/webpnft/b2.webp";
  const image3 =
  "./images/metalscupture/3.webp";
  const image4 =
  "./images/sitespecifcwebp/4.webp";
  const image5 =
  "./images/metalpillerwebp/5.webp";
  const image6 =
  "./images/sitespecifcwebp/6.webp";
  const image7 =
  "./images/metalpillerwebp/7.webp";
  const image8 =
  "./images/metalpillerwebp/8.webp";
  const image9 =
  "./images/reflectivemirrorwebp/9.webp";
  const image10 =
  "./images/metaldrwaningwebp/10.webp";
  const image11 =
  "./images/webpnft/a3.webp";
  const image12 =
  "./images/digitalprintwebp/12.webp";
  const image13 =
  "./images/metalpillerwebp/13.webp";
  const image14 =
  "./images/reflectivemirrorwebp/14.webp";
  const image15 =
  "./images/metalpillerwebp/15.webp";
  const image16 =
  "./images/digitalprintwebp/1.webp";



  const image17 =
  "./images/metalpillerwebp/10.webp";
  const image18 =
  "./images/webpnft/c2.webp";
  const image19 =
  "./images/metalscupture/19.webp";
  const image20 =
  "./images/sitespecifcwebp/7.webp";
  const image21 =
  "./images/metalpillerwebp/12.webp";
  const image22 =
  "./images/sitespecifcwebp/20.webp";
  const image23 =
  "./images/metalpillerwebp/15.webp";
  const image24 =
  "./images/metalpillerwebp/11.webp";
  const image25 =
  "./images/reflectivemirrorwebp/7.webp";
  const image26 =
  "./images/metaldrwaningwebp/20.webp";
  const image28 =
  "./images/webpnft/g3.webp";
  const image29 =
  "./images/digitalprintwebp/14.webp";
  const image30 =
  "./images/metalpillerwebp/9.webp";
  const image31 =
  "./images/reflectivemirrorwebp/20.webp";
  const image32 =
  "./images/metalpillerwebp/11.webp";
  const image27 =
  "./images/digitalprintwebp/15.webp";
  
  const images = [image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
    image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,image32
    ];
  return (
    <div className={Style.flex} >
 
<SideBar/>



   <div className={Style.flex_Slider}>
    {/* <Slider /> */}
    <Slider/>
    <SecondSection/>
    {/* <Gallery/> */}
    <div style={{width:"90%", margin:"auto"}}>

    <Gallery images={images}/>

    </div>
    {/* <FourthSection/> */}
    <ContactForm/>
    <Footer/>
    </div>
   </div>
  )
}

export default index