import React from 'react'
import Style from "./ServicerBanner.module.css"

const index = ({img,heading,detail}) => {
  return (
    <div className={`${Style.bannerContainer} container-fluid`}>
    <img src={img} alt="" />
    <div className={`${Style.serviceBanner} container`}>
        <div className={`${Style.heading}`}>
            <h1 className={Style.banner_heading}>{heading}</h1>
            
            <p className={Style.banner_content}>{detail}
            </p>

        </div>
    </div>
</div>
  )
}

export default index