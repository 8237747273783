import React from "react";
// import Slider from "../Layouts/Slider"
import Style from "./ScrumbledCircles.module.css";
// import ServiceContent from "../../Components/ServicesContent";
import SideBar from "../../Components/SideBar";
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import banner from "../../assets/scrumble.jpg";
import Footer from "../../Components/Layouts/Footer";
// import Content from "../../Components/Content";
// import SubProducts from "../../Components/subProducts";
// import ScumbledGallery from "../../Components/GallerySection/ScrumbledCirclesGallery"
import Gallery from "../../Components/GallerySection/SiteSpecificGallery"

const index = () => {
  const image1 =
  "./images/scrambledcirclewebp/1.webp";
  const image2 =
  "./images/scrambledcirclewebp/2.webp";
  const image3 =
  "./images/scrambledcirclewebp/3.webp";
  const image4 =
  "./images/scrambledcirclewebp/4.webp";
  const image5 =
  "./images/scrambledcirclewebp/5.webp";
  const image6 =
  "./images/scrambledcirclewebp/6.webp";
  const image7 =
  "./images/scrambledcirclewebp/7.webp";
  const image8 =
  "./images/scrambledcirclewebp/8.webp";
  const image9 =
  "./images/scrambledcirclewebp/9.webp";
  const image10 =
  "./images/scrambledcirclewebp/10.webp";
  const image11 =
  "./images/scrambledcirclewebp/11.webp";
  const image12 =
  "./images/scrambledcirclewebp/12.webp";
  const image13 =
  "./images/scrambledcirclewebp/13.webp";
  const image14 =
  "./images/scrambledcirclewebp/14.webp";
  const image15 =
  "./images/scrambledcirclewebp/15.webp";
  const image16 =
  "./images/scrambledcirclewebp/16.webp";
  const image17 =
"./images/scrambledcirclewebp/17.webp";
const image18 =
"./images/scrambledcirclewebp/18.webp";
const image19 =
"./images/scrambledcirclewebp/19.webp";
const image20 =
"./images/scrambledcirclewebp/20.webp";
const image21 =
"./images/scrambledcirclewebp/21.webp";
const image22 =
"./images/scrambledcirclewebp/22.webp";
const image23 =
"./images/scrambledcirclewebp/23.webp";
const image24 =
"./images/scrambledcirclewebp/24.webp";
const image25 =
"./images/scrambledcirclewebp/25.webp";

const image26 =
"./images/scrambledcirclewebp/26.webp";
const image27 =
"./images/scrambledcirclewebp/27.webp";
const image28 =
"./images/scrambledcirclewebp/28.webp";
const image29 =
"./images/scrambledcirclewebp/29.webp";
const image30 =
"./images/scrambledcirclewebp/30.webp";
const image31 =
"./images/scrambledcirclewebp/31.webp";



const image32 =
"./images/scrambledcirclewebp/32.webp";
const image33 =
"./images/scrambledcirclewebp/33.webp";
const image34 =
"./images/scrambledcirclewebp/34.webp";
const image35 =
"./images/scrambledcirclewebp/35.webp";
const image36 =
"./images/scrambledcirclewebp/36.webp";
const image37 =
"./images/scrambledcirclewebp/37.webp";
const image38 =
"./images/scrambledcirclewebp/38.webp";
const image39 =
"./images/scrambledcirclewebp/39.webp";
const image40 =
"./images/scrambledcirclewebp/40.webp";
const image41 =
"./images/scrambledcirclewebp/41.webp";
const image42 =
"./images/scrambledcirclewebp/42.webp";
const image43 =
"./images/scrambledcirclewebp/43.webp";
const image44 =
"./images/scrambledcirclewebp/44.webp";
const image45 =
"./images/scrambledcirclewebp/45.webp";
const image46 =
"./images/scrambledcirclewebp/46.webp";
const image47 =
"./images/scrambledcirclewebp/47.webp";
const image48 =
"./images/scrambledcirclewebp/48.webp";
const image49 =
"./images/scrambledcirclewebp/49.webp";
const image50 =
"./images/scrambledcirclewebp/50.webp";
const image51 =
"./images/scrambledcirclewebp/51.webp";
const image52 =
"./images/scrambledcirclewebp/52.webp";
const image53 =
"./images/scrambledcirclewebp/53.webp";
const image54 =
"./images/scrambledcirclewebp/54.webp";
const image55 =
"./images/scrambledcirclewebp/55.webp";
const image56 =
"./images/scrambledcirclewebp/56.webp";

const image57 =
"./images/scrambledcirclewebp/57.webp";
const image58 =
"./images/scrambledcirclewebp/58.webp";
const image59 =
"./images/scrambledcirclewebp/59.webp";
const image60 =
"./images/scrambledcirclewebp/60.webp";
const image61 =
"./images/scrambledcirclewebp/61.webp";
const image62 =
"./images/scrambledcirclewebp/62.webp";
const image63 =
"./images/scrambledcirclewebp/scramble1.webp";
const image64 =
"./images/scrambledcirclewebp/scramble2.webp";
const image65 =
"./images/scrambledcirclewebp/scramble3.webp";
const image66 =
"./images/scrambledcirclewebp/scramble4.webp";
const image67 =
"./images/scrambledcirclewebp/scramble5.webp";
const image68 =
"./images/scrambledcirclewebp/scramble6.webp";
const image69 =
"./images/scrambledcirclewebp/scramble7.webp";
const image70 =
"./images/scrambledcirclewebp/scramble8.webp";
const image71 =
"./images/scrambledcirclewebp/scramble9.webp";
const image72 =
"./images/scrambledcirclewebp/scramble10.webp";
const image73 =
"./images/scrambledcirclewebp/scramble11.webp";



  const images = [image63,image64,image65,image66,image67,image68,image69,image70,image71,image72,image73,image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
    image17,image18,image19,image20,image21,image22,image23,image24,image25,image26,image27,image28,image29,image30,image31,
  image32,image33,image34,image35,image36,image37,image38,image39,image40,image41,image42,image43,image44,image45,image46,image47,image48,image49,image50,image51,image52,image53,image54,image55,image56,image57,image58,image59,image60,image61,image62,
  ];
  // const list = [
  //   {
  //     value: "Available in a variety of sizes and shapes to suit your needs",
  //   },
  //   {
  //     value: "Made with high-quality materials for durability and longevity",
  //   },
  //   {
  //     value:
  //       "Easy to clean and maintain, ensuring long-lasting beauty and functionality",
  //   },
  // ];
  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
        <ServiceBanner
          heading="Scrambled Circles"
          // detail={
          //   "Add a touch of modern style to your space with our Scrambled Circles wall art. These pieces are perfect for those who love contemporary design and want to add a unique touch to their home or office decor. If you're looking for a unique and eye-catching piece of wall art, our Scrambled Circles collection is the perfect choice. With its intricate and abstract design, high-quality materials, and range of sizes, this art piece is sure to add a touch of modern style to any room in your home or office."
          // }
          img={banner}
        />
        <div className={Style.container}>
          {/* <ServiceContent list={list} listHeading="Features:" /> */}

          <div className={Style.products}>
            {/* 1 */}
            {/* <SubProducts
              image={"./images/Stereoself.jpg"}
              heading="Title : "
              detail={"Stereoself"}
              heading2="Dimension : "
              detail2={"122cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Black tint"}
            /> */}
            {/* 2 */}
            {/* <SubProducts
              image={"./images/Blue Lagoon .jpg"}
              heading="Title : "
              detail={" Blue Lagoon"}
              heading2="Dimension : "
              detail2={"122cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Aqua tint "}
            /> */}
            {/* 3 */}
            {/* <SubProducts
              image={"./images/Golden eye.jpg"}
              heading="Title : "
              detail={"In to the Sun"}
              heading2="Dimension : "
              detail2={" 152cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={"Gold tint"}
            /> */}
            {/* 4 */}
            {/* <SubProducts
              image={"./images/Fare Trade.jpg"}
              heading="Title : "
              detail={" Fare Trade"}
              heading2="Dimension : "
              detail2={"122cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={" Green tint "}
            /> */}
            {/* 5 */}
            {/* <SubProducts
              image={"./images/In to the Sun.jpg"}
              heading="Title : "
              detail={"Golden eye "}
              heading2="Dimension : "
              detail2={"122cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={" Rose gold tint"}
            /> */}
            {/* 6 */}
            {/* <SubProducts
              image={"./images/The other completes .jpg"}
              heading="Title : "
              detail={"The other completes"}
              heading2="Dimension : "
              detail2={"244cm diameter"}
              heading3="Medium : "
              detail3={"Stainless Steel"}
              heading4="Color : "
              detail4={" Rose Gold tint"}
            /> */}
          </div>
          {/* <Content
            heading={"Design and Materials: "}
            detail="Our Reflective Ovals wall art features an abstract and geometric design that is sure to catch the eye. The ovals are arranged in a pattern that creates a sense of movement and depth, while the reflective finish adds a touch of elegance and sophistication. We use only the highest quality materials in the creation of our Reflective Ovals wall art. Each piece is crafted from durable and long-lasting materials, ensuring that it will look great for years to come. The reflective finish is also fade-resistant, ensuring that the piece maintains its shine over time."
            img="./images/6-2.jpg"
          />
          <Content
            heading={"Sizes and Versatility:  "}
            detail="Our Scrambled Circles wall art is available in a range of sizes to suit your needs. Whether you're looking for a small accent piece or a large statement piece, we have something that will fit your space perfectly. Our Scrambled Circles wall art is incredibly versatile and can be used in a variety of spaces. Use it in your living room, bedroom, office, or any other room where you want to add a touch of modern style. It also makes a great gift for anyone who loves contemporary design."
          />
            <h2 className={Style.heading_bottom}>Image's Gallery</h2>
            <ScumbledGallery/> */}

          {/* <h2 className={Style.heading_bottom}>Installation:</h2>
          <p>
            Installation of our Scrambled Circles wall art is quick and easy.
            Each piece comes with all the necessary hardware and instructions,
            making it easy for anyone to install. Simply follow the included
            instructions, and your new art piece will be up and ready to enjoy
            in no time.
          </p> */}
                     <Gallery images={images}/>

        </div>
        <Footer />
      </div>
    </div>
  );
};

export default index;
