import React from "react";
// import Slider from "../Layouts/Slider"
import Style from "./pilerMetal.module.css";
// import ServiceContent from "../../Components/ServicesContent";
import SideBar from "../../Components/SideBar";
import ServiceBanner from "../../Components/Layouts/ServicesBanner";
import banner from "../../assets/metal piller.jpg";
import Footer from "../../Components/Layouts/Footer";
// import Content from "../../Components/Content";
// import SubProducts from "../../Components/subProducts";
// import MetalPillerGallery from "../../Components/GallerySection/metalPillerGallery";
import Gallery from "../../Components/GallerySection/SiteSpecificGallery"


const index = () => {
  const image1 =
"./images/metalpillerwebp/1.webp";
const image2 =
"./images/metalpillerwebp/2.webp";
const image3 =
"./images/metalpillerwebp/3.webp";
const image4 =
"./images/metalpillerwebp/4.webp";
const image5 =
"./images/metalpillerwebp/5.webp";
const image6 =
"./images/metalpillerwebp/6.webp";
const image7 =
"./images/metalpillerwebp/7.webp";
const image8 =
"./images/metalpillerwebp/8.webp";
const image9 =
"./images/metalpillerwebp/9.webp";
const image10 =
"./images/metalpillerwebp/10.webp";
const image11 =
"./images/metalpillerwebp/11.webp";
const image12 =
"./images/metalpillerwebp/12.webp";
const image13 =
"./images/metalpillerwebp/13.webp";
const image14 =
"./images/metalpillerwebp/14.webp";
const image15 =
"./images/metalpillerwebp/15.webp";
const image16 =
"./images/metalpillerwebp/16.webp";

const images = [image1, image2, image3, image4, image5, image6,image7,image8,image9,image10,image11,image12,image13,image14,image15,image16,
  ];
  // const list = [
  //   {
  //     value: "Made of high-quality metal for durability and longevity",
  //   },
  //   {
  //     value: "Available in a variety of sizes and finishes to suit your needs",
  //   },
  //   {
  //     value:
  //       "Easy to clean and maintain, ensuring long-lasting beauty and functionality",
  //   },
  // ];
  return (
    <div className={Style.flex}>
      <SideBar />

      <div className={Style.flex_Slider}>
        <ServiceBanner
          heading="Metal Pillars"
          // detail={
          //   "Enhance the look of your space with our Metal Pillars. These sleek and stylish pillars are designed to complement any interior decor, adding an elegant touch to your home or office. If you're looking for a stylish and versatile way to enhance the look of your space, our Metal Pillars are the perfect choice. With their sleek design, high-quality materials, and range of finishes, these pillars are sure to add an elegant touch to any interior decor"
          // }
          img={banner}
        />
            <div className={Style.Container}>
        {/* <ServiceContent list={list} listHeading="Features:" /> */}
        <div className={Style.products}>
          {/* 1 */}
        {/* <SubProducts
        image={"./images/fineries.JPG"}
        heading="Title : "
        detail={"Fineries"}
        heading2="Dimension : "
        detail2={" 244 x 60cm each piece"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={"Gold tint"}
        
        />
       
        <SubProducts
        image={"./images/twintower .jpg"}
        heading="Title : "
        detail={" Twin Tower"}
        heading2="Dimension : "
        detail2={" 44 x 122cm  each side"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={"Gold tint"}
        
        /> */}
        {/* 3 */}
           {/* <SubProducts
        image={"./images/parallels.jpg"}
        heading="Title : "
        detail={"Parallels"}
        heading2="Dimension : "
        detail2={" 244 x 30cm each side"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={"Aqua tint"}
        
        /> */}
        {/* 4 */}
           {/* <SubProducts
        image={"./images/parallesingold.jpg"}
        heading="Title : "
        detail={"Parallels in Gold"}
        heading2="Dimension : "
        detail2={" 244 x 30cm each side"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={"Gold tint"}
        
        />
        {/* 5 */}
           {/* <SubProducts
        image={"./images/Alovera.jpg"}
        heading="Title : "
        detail={"Alovera"}
        heading2="Dimension : "
        detail2={"  244 x 30cm each side"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={"Green tint"}
        
        /> */} 
        {/* 6 */}
           {/* <SubProducts
        image={"./images/Contemporary.jpg"}
        heading="Title : "
        detail={"Contemporary"}
        heading2="Dimension : "
        detail2={" 244 x 122cm"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={" Rose gold tint"}
        
        /> */}
        {/* 7 */}
            {/* <SubProducts
        image={"./images/Duplicate.jpg"}
        heading="Title : "
        detail={"Duplicate"}
        heading2="Dimension : "
        detail2={"244 x 30cm each side"}
        heading3="Medium : "
        detail3={"Stainless Steel"}
        heading4="Color : "
        detail4={" Rose Gold and Silver tint"}
        
        /> */}
        </div>
        {/* <Content
          heading={"Design and Materials: "}
          detail="Our Metal Pillars are designed with both form and function in mind. The clean lines and minimalist design create a contemporary look that is perfect for modern interiors. The pillars can be used in a variety of ways, such as a standalone piece or as a base for other decorative elements. We use only the finest quality materials to ensure that our Metal Pillars are durable and long-lasting. Each pillar is crafted from high-quality metal that is resistant to corrosion and wear, ensuring that it maintains its beauty for years to come."
          img="./images/1-1.jpg"
        /> */}

        {/* <Content
          heading={"Finishes and Versatility: "}
          detail={
            "Our Metal Pillars are available in a range of finishes to suit your personal style and interior decor. Choose from a sleek and shiny silver finish, a bold and modern black finish, or a warm and inviting bronze finish. Our Metal Pillars are highly versatile, and can be used in a variety of ways to enhance the look of your space. Use them as standalone pieces to create a striking focal point, or as a base for other decorative elements such as vases, sculptures, or floral arrangements"
          }
          img="./images/51.jpg"
        /> */}
          {/* <h2 className={Style.heading_bottom}>Image's Gallery</h2> */}
       {/* <MetalPillerGallery/> */}
       <Gallery images={images}/>
        {/* <h2 className={Style.heading_bottom}>Installation:</h2>
        <p>Installation of our Metal Pillars is quick and easy, and can be done by anyone with basic DIY skills. Simply follow the included instructions, and your new Metal Pillars will be up and ready to use in no time.
        </p> */}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default index;
